import {
  createRouter,
  createWebHistory
} from 'vue-router'

import AudioGuide from '@/views/AudioGuide.vue'

const routes = [{
  path: '/',
  name: 'AudioGuide',
  component: AudioGuide
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router