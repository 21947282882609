//eslint-disable-next-line
import { createApp } from 'vue'
//eslint-disable-next-line
import styles from './assets/main.css';
import App from './App.vue'
import router from './router'

// const HomeV = { template: Home }
// const AudioGuideV = { template: AudioGuide }

// const routes = [
//     { path: '/', component: HomeV },
//     { path: '/audioguide', component: AudioGuideV },
//   ]

// const routerV = VueRouter.createRouter({
//     // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//     history: VueRouter.createWebHashHistory(),
//     routes, // short for `routes: routes`
//   });

//   const app = Vue.createApp({})
//   app.use(routerV)
//   app.mount('#app')

  createApp(App).use(router).mount('#app')