<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: LynoJean, Avenir, Helvetica, Arial, sans-serif, UniversLTStd,
    ABCFavoritEduReg, ABCFavoritEdu, ABCFavoritEduMed, ABCFavoritEduLining,
    ABCFavoritEduLiningReg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  /* margin-top: 60px; */
}

body {
  margin: 0 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
