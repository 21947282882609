<template>
  <div class="agContainer">
    <header class="header">
      <img class="agHeaderImage" src="../assets/img/headline.png" /><img />
    </header>

    <article class="agListContainer">
      <div class="listTitle"><h2>Audioguide</h2></div>
      <ul class="agList">
        <li id="one">
          <span>01</span>Fall Fretterode
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="two">
          <span>02</span>Gutshaus Hanstein
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="three">
          <span>03</span>Thorsten Heise
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="four">
          <span>04</span>FAP Verbot
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="five">
          <span>05</span>Rechtsrock
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="six">
          <span>06</span>Firmengeflecht
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
        <li id="seven">
          <span>07</span>NPD und Björn Höcke
          <button class="agButton" v-on:click="playMusic">
            <img src="../assets/img/play.png" />
          </button>
        </li>
      </ul>
    </article>
    <div>
      <audio ref="player" id="player" class="aplayer">
        <source src="../assets/audio2/01.mp3" type="audio/mpeg" />
      </audio>
    </div>

    <footer class="agFooter">
      <p>
        Hier findest du gesprochene Hintergrundinformationen zum Netzwerk.
        Während du den Audios lauschst, kannst du die Verbindungen auf der Karte
        suchen und nachverfolgen.
      </p>
      <div class="disclaimer">
        <h3>Disclaimer</h3>
        <p>
          Der Audioguide und die Grafik »Neonazi Netzwerk« besitzen keinen
          Anspruch auf Vollständigkeit noch Aktualität. Die meisten
          Informationen wurden aus der Broschüre »Zwischen Gewalt, RechtsRock
          und Kommerz« gezogen, welche vom Journalisten Kai Budler verfasst
          wurde. Budler konnte die Netzwerke aufgrund seiner langjährigen
          investigativen Arbeit in rechten Szenen aufdecken. Da die Broschüre im
          Januar 2020 von MOBIT e.V. herausgegeben wurde, sind teilweise
          Verknüpfungen veraltet.
        </p>
      </div>
      <div class="quellen">
        <h3>Quellen</h3>
        <ul>
          <li>
            Budler, K. (2020) Zwischen Gewalt, RechtsRock und Kommerz, Der
            Multifunktionär Thorsten Heise; Hrsg. MOBIT e.V. [Broschüre]
          </li>
          <li>
            Prozessakte des Prozesses gegen Täter A und B im Fall Fretterode
            (2021)[Dokumente]
          </li>
          <li>
            https://tatort-fretterode.org (Zuletzt aufgerufen am
            11.01.2024)[Website]
          </li>
        </ul>
      </div>
      <div class="anmerkungen">
        <h3>Anmerkungen</h3>
        <p>
          1 Die NPD hat sich seit Juni 2023 in »die Heimat« umbenannt. Da die
          verwendete Quelle von 2020 stammt, wird hier noch von der NPD
          gesprochen.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AudioGuide",
  props: {
    msg: String,
  },
  methods: {
    playMusic: function (event) {
      var parent = event.target.parentElement.parentElement;
      var number = parent.firstChild.innerHTML;
      var img = event.target;
      var audio = this.$refs.player;
      var encoding = ".mp3";
      console.log(audio);

      //first pause the current audio does not matter which one it is
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
        console.log("stoped audio");
      }

      //LEAVE AND CLEAR CURRENT AUDIO - stopping the same audio
      if (number == this.currentAudio) {
        img.src = require("@/assets/img/play.png");
        // img.classlist.remove('footerImagePause');
        console.log("number == src = " + img.src);
        this.currentAudio = "";
        return;
      }

      //change old audio to play sign
      if (this.oldRef != null) {
        this.oldRef.src = require("@/assets/img/play.png");
      }

      //play the audio we want to play
      this.currentAudio = number;
      this.oldRef = img;
      // if (number == "02" || number == "06") {
      //   encoding = ".mpeg"
      // }

      var audioString = "audio2/" + number + encoding;
      audio.src = require("@/assets/" + audioString);
      audio.play();
      console.log("playing audio " + audio.src);

      this.isPlaying = true;
      img.src = require("@/assets/img/pause.png");
      // img.classlist.add('footerImagePause');
    },
  },
  data: function () {
    return {
      currentAudio: "",
      isPlaying: false,
      oldRef: null,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// import scss
@import "@/assets/mediaQuerys.scss";

.aplayer {
  display: none;
}

.agContainer {
  color: black;
  // height: 100vh;
  min-height: 100vh;
  height: 100%;

  @include tablet_portrait_size {
    padding-left: 10%;
    padding-right: 10%;
  }

  @include desktop_size {
    padding-left: 20%;
    padding-right: 20%;
  }

  /** HEADER */
  .header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 35px;
    padding-top: 35px;
  }
  .agHeaderImage {
    width: 100%;
  }
  .listTitle {
    display: flex;
    margin-bottom: 20px;
    h2 {
      margin: 0 !important;
      padding: 0;
      padding-left: 10px;
      margin: 0;
      font-family: "ABCFavoritEduLining";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  /** CONTAINER */
  .agListContainer {
    font-family: "ABCFavoritEdu";
    @include tablet_portrait_size {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include desktop_size {
      padding-left: 100px;
      padding-right: 100px;
    }

    p {
    }

    ul {
      font-family: "ABCFavoritEdu";
      list-style-type: none;
      margin: 0;
      padding: 0;
      #one {
        background: #e0dce5 !important;
      }
      #two {
        background: #c6cfd2 !important;
      }
      #three {
        background: #fff !important;
      }
      #four {
        background: #d6cbbd;
      }
      #five {
        background: #e9e8cc;
      }
      #six {
        background: #c8d0bb;
      }
      #seven {
        // background: #e4f0f6;
        border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;
        button {
          opacity: 0.3;
          cursor: not-allowed;
          // color: lightgrey;
          img{
           
            color: #666666;
          }
        }
      }
    }

    li {
      font-family: "ABCFavoritEduReg";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;

      display: flex;
      margin-left: 10px;
      margin-right: 15px;
      margin-top: 15px;

      padding: 0;
      padding-top: 17px;
      padding-bottom: 12px;
      padding-left: 17px;
      padding-right: 17px;
      border: 2px solid #000;

      &:nth-child(1) {
        margin-top: 0;
      }

      span {
        padding-right: 20px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
      }
      button {
        margin-left: auto;

        /* background: url("../assets/img/play.png"); */
        /* background-color: white; */
        background: none;

        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        img {
          width: 14px;
          height: 16px;
        }
      }
    }
  }
  /** FOOOTER */

  .agFooter {
    font-family: "ABCFavoritEdu";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // font-family: "ABCFavoritEdu";
    // font-size: 9px;
    // line-height: 137.8%;
    // font-style: normal;
    // font-weight: 700;

    display: flex;
    flex-direction: column;

    margin-top: 30px;
    text-align: left;
    padding: 15px;
    padding-top: 0;
    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }
    h3 {
      color: #000;
      font-family: "ABCFavoritEduLiningReg";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .disclaimer {
      margin-top: 50px;
      font-family: "ABCFavoritEduReg" !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
    }
    .anmerkungen {
      font-family: "ABCFavoritEduReg" !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
    }
    .quellen {
      font-family: "ABCFavoritEduReg" !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;

      ul {
        margin: 0;
        padding-left: 20px;
        li {
          margin: 0;
        }
      }
    }
  }
}
</style>

<style scoped></style>
